import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
  FieldValue,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./App.scss";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [countryCode, setCountryCode] = useState("us");
  const [countryData, setCountryData] = useState({});

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        setCountryData(response.data);
        setCountryCode(response.data.country_code.toLowerCase());
      } catch (error) {
        console.error("Error fetching country code: ", error);
      }
    };

    fetchCountryCode();
  }, []);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format
    return phoneRegex.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(phone)) {
      setError("Invalid phone number. Please enter a valid WhatsApp number.");
      return;
    }
    setError("");
    try {
      let doc = await getDocs(
        query(collection(db, "contacts"), where("phone", "==", phone))
      );
      if (doc.docs.length > 0) {
        alert("You have already submitted your details. Thank you!");
        return;
      } else {
        await addDoc(collection(db, "contacts"), {
          ...countryData,
          name,
          phone,
          time_stamp: Timestamp.now(),
        });
        alert("You have signed up successfully!");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Container maxWidth="sm" className="wrapper">
      <Box sx={{ mt: 5 }}>
        <center>
          <img
            width={"150px"}
            style={{ borderRadius: "50%" }}
            src="https://storage.googleapis.com/corporate-tracker/corporate-tracker-logo.jpg"
          />
        </center>
        <br />
        <br />
        <Typography variant="h4" component="h1" gutterBottom>
          <b>Corporate Tracker Sign Up</b>
        </Typography>
        <p>
          Sign up for a chance to win £100 by answering a quick 5 question
          survey through WhatsApp!
        </p>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <PhoneInput
            country={countryCode}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            inputStyle={{ width: "100%" }}
            containerStyle={{ marginBottom: "16px" }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            isValid={(value, country) => {
              if (value.match(/^\+?[1-9]\d{1,14}$/)) {
                setError("");
                return true;
              } else {
                setError(
                  "Invalid phone number. Please enter a valid WhatsApp number."
                );
                return false;
              }
            }}
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default App;
